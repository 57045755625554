<template>
  <div class="getCoupon">
    <img src="../../assets/img/getcode-icon.png"
         alt
         class="imgicon" />
    <h4 class="price">
      You've saved
      <span>${{conpouPrice}}</span>
    </h4>
    <p class="anytime">Coupon may expires at anytime.Use it now!</p>
    <div class="code">{{conpouList.coupon_code}}</div>
    <a round
       class="gotoBtn"
       :href="conpouList.amazon_url">Copy and Go to Amazon</a>
    <div class="warning">
      <i class="el-icon-warning"></i>
      <span>This coupon code can only be applied to specific variations of this product.</span>
    </div>
  </div>
</template>

<script>
// 引入接口
// 获取折扣商品详情
import { conpouDetail } from '../../api/buyerFront'
export default {
  data () {
    return {
      conpouList: [],
      conpouPrice: ''
    }
  },
  created () {
    // 获取折扣商品详情
    conpouDetail({
      productId: this.$route.query.goodId
    }).then(result => {
      if (result.data.code === 200) {
        this.conpouList = result.data.data
        this.conpouPrice = result.data.data.sale_price - result.data.data.deal_price
      }
      // ('result ==>', result)
    }).catch(err => {
      return err
    })
    // (this.$route)
  }
}
</script>

<style lang="less" scoped>
.getCoupon {
  width: 100%;
  height: 700px;
  background-color: #f2f2f2;
  padding-top: 100px;
  text-align: center;

  .imgicon {
    width: 230px;
    height: 230px;
    margin-bottom: 20px;
  }
  .price {
    color: #27a6e0;
    font-size: 32px;
    margin-bottom: 10px;
  }
  .anytime {
    color: grey;
    font-size: 16px;
    margin-bottom: 56px;
  }
  .code {
    width: 340px;
    height: 50px;
    background-color: #888;
    color: white;
    font-weight: bold;
    margin: 0 auto;
    font-size: 26px;
    font-weight: bolder;
    line-height: 50px;
    margin-bottom: 21px;
  }
  .gotoBtn {
    display: inline-block;
    font-size: 36px;
    background-color: #27a6e0;
    color: white;
    margin-bottom: 12px;
    border-radius: 30px;
    padding: 10px;
  }
  .warning {
    color: gray;
    font-size: 16px;
    span {
      margin-left: 3px;
    }
  }
}
</style>
